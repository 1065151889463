import RangeEstimation from "components/UI/rangeEstimation/RangeEstimation"
import { useDeviceType } from "components/hooks/useDeviceType"
import classes from './CheckMarkBlock.module.css'
const CheckMarkBlock = ({ mass, grades, setGrades }) => {

    const device = useDeviceType()
    const maxInBlock = 6
    let index = 1
    let result = []
    const blockCount = Math.ceil(mass?.length / maxInBlock)
    for (let i = 0; i < blockCount; i++) {
        const massCopy = [...mass.slice(i * maxInBlock, i * maxInBlock + maxInBlock)]
        result.push(
            (device === "phone" || window.innerWidth <= 820) ?
                massCopy.map((value, j) => <div className={classes.checkMarkBlock__table} key={index + j} style={{ boxShadow: "0px 0px 10vw -2vw #00000040", border: "0.9vw solid var(--main-color)", borderRadius: "3vw", padding: "0.1vw 4vw", margin: "4vw 0vw" }}>
                    <RangeEstimation setGrades={setGrades} grades={grades} name={`m${index + j}`} max={value.grade} text={value.name} />
                </div>)
                :
                <div key={index} style={{ margin: "2vw 0vw", display: "flex", flexDirection: "row", width: "100%", boxShadow: "0px 0px 0.5vw 0px #00000040", borderRadius: "1.2vw", border: "solid 0.2vw var(--main-color-border)", justifyContent: "space-around" }}>
                    {massCopy.map((value, j) => <RangeEstimation setGrades={setGrades} grades={grades} name={`m${index + j}`} key={index + j} max={value.grade} text={value.name} />)}
                </div>
        )
        index += 6
    }
    return result
}

export default CheckMarkBlock