import { useDeviceType } from "components/hooks/useDeviceType";
import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import './Navbar.css'

function NavbarItem({ name, title, Icon, setItemRef }) {
    const device = useDeviceType()
    const location = useLocation()
    const ref = useRef(null)

    const [innerWidth, setInnerWidth] = useState(window.innerWidth)

    useEffect(() => {
        if (location.pathname.slice(1) === name) {
            ref.current.className = 'nav-item active'
            setItemRef(ref)
        }
        else { ref.current.className = 'nav-item' }
    }, [location])

    useEffect(() => {
        const handleResize = () => setInnerWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])


    return (
        <li className="nav-item" ref={ref}>
            <NavLink className="nav-link" to={`/${name}`} exact="true">
                {innerWidth > 1281 ? title : <Icon className={'admin-navbar-icon'} />}
            </NavLink>
        </li>
    );
}

export default NavbarItem;