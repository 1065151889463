import classes from './DropDownList.module.css'
import { useEffect, useState } from "react";
import DropDownItem from './DropDownItem'
import { useDeviceType } from 'components/hooks/useDeviceType';


function DropDownList({ children, width = 60, fullWidth = false, fullWidthOnMobile = true, indexStatus, zIndex, ...props }) {
    const [list, setList] = useState([]);
    const device = useDeviceType()
    const [height, setHeight] = useState(device === 'desktop' ? 2.3 : 7);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth)

    // const listWidth = fullWidth ? '100%' : ((fullWidthOnMobile && device === 'phone') ? 'calc(100% - 10px - 10px)' : `calc(${width}vw - 10px - 10px)`)
    // const itemWidth = fullWidth ? '100%' : ((fullWidthOnMobile && device === 'phone') ? 'calc(90% - 10px - 10px)' : `calc(${width}vw - 10px - 10px)`)
    const listWidth = fullWidth ? '100%' : ((fullWidthOnMobile && device === 'phone') ? '100%' : `calc(${width}vw - 10px - 10px)`)
    const itemWidth = fullWidth ? '100%' : ((fullWidthOnMobile && device === 'phone') ? '90%' : `calc(${width}vw - 10px - 10px)`)

    const dropDownListUpdate = (key, text) => <DropDownItem indexStatus={indexStatus} width={itemWidth} onClick={props.setValue} index={key} key={key}>{text}</DropDownItem >

    const getSizee = () => (device === 'phone' ? 10 : innerWidth < 1281 ? 3.35 : 2.05)

    useEffect(() => {
        const listAfterFiltering = props.list.filter(item => item.includes(props?.filter))
        setHeight(getSizee() * listAfterFiltering.length)
        setList(listAfterFiltering.map((item, i) => dropDownListUpdate(i, item)))
    }, [props?.filter, props.list])

    useEffect(() => {
        const handleResize = () => setInnerWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])


    return (
        <div className={classes.wrapper}
            style={{
                width: listWidth,
                height: `${props.isActive ? height : 0}vw`,
                overflowY: (height / getSizee() > (device === 'phone' ? 4 : 7) ? 'scroll' : 'auto'),
                zIndex: zIndex - 1,
                paddingTop: !props.isActive && '0'
            }}>
            {list}
        </div>
    )
}

export default DropDownList;