import React, { useEffect, useState } from "react";
import { Range, getTrackBackground } from "react-range";
import classes from "./RangeEstimation.module.css"
import { useDeviceType } from "components/hooks/useDeviceType";

function RangeEstimation({ name, grades, setGrades, ...props }) {
    const device = useDeviceType()
    const STEP = 1;
    const MIN = 0;
    const MAX = props.max;
    const [values, setValues] = useState(grades[name] ? [grades[name]] : [0]);

    const deviceType = useDeviceType()
    const changeValues = (val) => {
        setValues(val)
        setGrades(old => ({ ...old, [name]: parseFloat(parseFloat(val[0]).toFixed(0)) }))
    }

    useEffect(() => setValues(grades[name] ? [grades[name]] : [0]), [grades[name]])

    const size = (deviceType === 'table') ? '2.5vw' : '2vw'
    const sizeCircle = (deviceType === 'table') ? '5.5vw' : '3.5vw'
    const fontSize = (deviceType === 'table') ? '2.1vw' : '1.1vw'

    return (
        (device === "phone" || window.innerWidth <= 820) ?
            <div
                className={classes.rangeEstimation__wrapper}
                style={{
                    display: "flex",
                    flexDirection: 'column',
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    gap: "2vw",
                    margin: "4vw 1vw",
                    width: "100%"
                }}
            >
                <div className={classes.text__style__block}>
                    <span>
                        {props.text}
                    </span>
                </div>
                <Range
                    values={values}
                    step={STEP}
                    min={MIN}
                    max={MAX}
                    onChange={changeValues}
                    renderTrack={({ props, children }) => (
                        <div
                            // onMouseDown={props.onMouseDown}
                            // onTouchStart={props.onTouchStart}
                            style={{
                                ...props.style,
                                height: "auto",
                                display: "flex",
                                width: "90%",
                                border: "none"
                            }}
                        >
                            <div
                                className={classes.rangeEstimation__Range}
                                ref={props.ref}
                                style={{
                                    height: "3vw",
                                    width: "100%",
                                    borderRadius: "0.5vw",
                                    background: getTrackBackground({
                                        values,
                                        colors: ["var(--main-color)", "#f0f0f0"],
                                        min: MIN,
                                        max: MAX
                                    }),
                                    alignSelf: "center"
                                }}
                            >
                                {children}
                            </div>
                        </div>
                    )}
                    renderThumb={({ props, isDragged }) => (
                        <div
                            className={classes.rangeEstimation__renderThumb}
                            {...props}
                            style={{
                                ...props.style,
                                height: "10vw",
                                width: "10vw",
                                borderRadius: "2vw",
                                backgroundColor: "#FFF",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                boxShadow: "0vw 0vw 2vw #AAA"

                            }}
                        >
                            <div
                                className={classes.rangeEstimation__renderThumb_more}
                                style={{
                                    height: "10vw",
                                    width: "10vw",
                                    borderRadius: "2vw",
                                    backgroundColor: isDragged ? "var(--main-color)" : "#242424"
                                }}
                            />
                        </div>
                    )}
                />
                <div className={classes.rangeEstimation__result} style={{ marginTop: "4vw", width: "17vw", height: "17vw", borderRadius: "50vw", backgroundColor: (values[0] && parseFloat(values[0]).toFixed(2)) < (MAX * 0.33) ? "#bd362f" : ((MAX * 0.33) < (values[0] && parseFloat(values[0]).toFixed(2)) && (values[0] && parseFloat(values[0]).toFixed(2)) < (MAX * 0.66) ? "#f89406" : "#51a351"), display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <output style={{ fontSize: "5vw", color: "white", fontWeight: "bold" }} id="output">
                        {(values[0] && parseFloat(values[0]).toFixed(0))}
                    </output>
                </div>

            </div>
            :
            <div
                style={{
                    display: "flex",
                    flexDirection: 'column',
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    gap: "1.5vw",
                    margin: "1vw 1vw",
                    width: "100%"
                }}
            >
                <div className={classes.text__style__block}>
                    <span>
                        {props.text}
                    </span>
                </div>
                <Range
                    values={values}
                    step={STEP}
                    min={MIN}
                    max={MAX}
                    onChange={changeValues}
                    renderTrack={({ props, children }) => (
                        <div
                            // onMouseDown={props.onMouseDown}
                            // onTouchStart={props.onTouchStart}
                            style={{
                                ...props.style,
                                height: "auto",
                                display: "flex",
                                width: "90%",
                                border: "none"
                            }}
                        >
                            <div
                                ref={props.ref}
                                style={{
                                    height: "1vw",
                                    width: "100%",
                                    borderRadius: "0.5vw",
                                    background: getTrackBackground({
                                        values,
                                        colors: ["var(--main-color)", "#f0f0f0"],
                                        min: MIN,
                                        max: MAX
                                    }),
                                    alignSelf: "center"
                                }}
                            >
                                {children}
                            </div>
                        </div>
                    )}
                    renderThumb={({ props, isDragged }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: size,
                                width: size,
                                borderRadius: "0.5vw",
                                backgroundColor: "#FFF",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                boxShadow: "0vw 0vw 0.5vw #AAA"
                            }}
                        >
                            <div
                                style={{
                                    height: size,
                                    width: size,
                                    borderRadius: "0.5vw",
                                    backgroundColor: isDragged ? "var(--main-color)" : "#242424"
                                }}
                            />
                        </div>
                    )}
                />

                <div style={{ width: sizeCircle, height: sizeCircle, borderRadius: "50vw", backgroundColor: (values[0] && parseFloat(values[0]).toFixed(0)) < (MAX * 0.34) ? "#bd362f" : ((MAX * 0.33) < (values[0] && parseFloat(values[0]).toFixed(0)) && (values[0] && parseFloat(values[0]).toFixed(0)) < (MAX * 0.67) ? "#f89406" : "#51a351"), display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <output style={{ fontSize: fontSize, color: "white", fontWeight: "bold" }} id="output">
                        {(values[0] && parseFloat(values[0]).toFixed(0))}
                    </output>
                </div>

            </div>

    );
}
export default RangeEstimation;
