const Strings = {
    /*  Occasion */
    eventTitle: "Название мероприятия",
    eventDate: "Дата проведения",
    eventTime: "Время",
    eventButtonText: "Участвовать",
    /*  Sidebar */
    sidebarTitle1: "СурГУ",
    sidebarTitle2: "Мероприятия",
    sidebarMain: "Главная",
    sidebarOccasion: "Мероприятия",
    sidebarCase: "Кейсы",
    sidebarSupport: "Контакты",
    sidebarExit: "Выход",
    sidebarEnter: "Вход",
    sidebarAdminPanel: "Админ панель",
    sidebarGrade: "Оценка команд",
    sidebarProfile: "Профиль",
    /*  TeamRoom */
    teamRoomDesk0: "Стол за командой зарезервирован!",
    /*  NavBar */
    navbarCreate: "Создание мероприятия",
    navbarReport: "Выгрузка отчета",
    navbarChangePart: "Назначение ролей",
    navbarNews: "Создание новости",
    navbarAdministration: "Администрирование",
    navbarWorkOnCase: "Работа с кейсами",
    navbarCertificate: "Сертификаты",
    navbarGrade: "Оценка команд",
    /*  CaseContainer */
    caseRedText: "Сообщение!",
    caseText: "Кейсы появляются автоматически во время проведения мероприятия, посетите раздел мероприятия и узнайте, что скоро состоится.",
    occasionText: "На данный момент нет активных мероприятий, ожидайте ближайших новостей",
    /*  Loader */
    loaderText: "Загрузка",
    /* Main */
    navbarMain: "Главная",
    navbarMainNews: "Новости",
    /* MainInfo */
    notificationsWarningDataEmptyProfile: "Заполните пожалуйста профиль",
    /* InfoTvStatPage */
    infoTvStatTitle: 'Общая информация о мероприятии',
    /* Profile */
    navbarProfilMentorPage: "Команда",
    navbarProfilCheckPoint: "Контрольная точка",
    navbarProfilFinalMark: "Финальная оценка",
    navbarInfoAboutCommand: "Тех. оценка",
    navbarProfileData: "Личные данные",
    navbarProfileOccasion: "Сертификаты",
    navbarProfileCase: "Работа с кейсами",
    /* Notifications */
    notificationsPoliticsTitle: "Примите политику соглашения!",
    notificationsInfoTitle: "Оповещение",
    notificationsSuccessTitle: "Успешно",
    notificationsWarningTitle: "Предупреждение",
    notificationsErrorTitle: "Ошибка",
    notificationsCopyData: 'Данные скопированы в буфер',
    notificationsChangeStatus: 'Статус изменен',
    notificationsAuth: 'Авторизация выполнена',
    notificationsLogout: 'Выход выполнен',
    notificationsDataUpdate: "Операция прошла успешно",
    notificationsLeaveCommand: "Вы успешно покинули команду",
    notificationsReportCreate: "Отчет успешно сгенерирован",
    notificationsReportCreateCopyLinkToBuffer: "Ссылка на отчет скопирована в буфер обмена",
    notificationsCertificateGenerated: "Сертификат успешно сгенерирован",
    notificationsRemoveImage: "Изображение удалено",
    notificationsCaseCreated: "Кейс успешно добавлен",
    notificationsRemoveFile: "Файл удален",
    notificationsAddImage: "Изображение добавлено",
    notificationsAddFile: "Файл добавлено",
    notificationsWarningReadBuffer: "Не удалось прочитать содержимое буфера обмена: ",
    notificationsAddNews: "Новость создана",
    notificationsWarningDataEmptyNews: "Заполните все поля!",
    notificationsErrorCaseWasRejected: "Кейс был отклонен администратором",
    /* ProfileMentorPage */
    profilieMentorTitle: "Информация о командах",
    profilieMentorUndefinedOccasionId: "Мероприятие скоро начнется",
    /* Profile */
    name: "Имя",
    /* Portfolio */
    portfolioText: "У Вас еще нет завершенных мероприятия",
    /* Create Case*/
    createCase: "Создание кейса",
    /* Update Case*/
    updateCase: "Изменение кейса",
    notificationsCaseCUpdated: "Кейс отправлен на рассмотрение",
    /* Team Room */
    teamRoom: "Команда",
    /* AdministrationPage */
    administrationPage: {
        currentOccasion: { active: "Активно", inActive: "Завершено" },
        currentOccasionButton: { active: "Начать", inActive: "Завершить" },
        currentOccasionTask: { active: "Опубликовано", inActive: "Не опубликовано" },
        currentOccasionTaskButton: { active: "Опубликовать", inActive: "Снять" },
        currentNewsButton: { active: "Опубликовать", inActive: "Снять" },
        currentNews: { active: "Опубликовано", inActive: "Снято с публикации" },
    },
    /* MentorCase*/
    mentorCaseNothing: "У Вас еще нет поданных кейсов, зайдите на страницу «Кейсы» и предложите свой вариант задачи...",
    /* profile-mentor-page*/
    mentorProfileNothingCase: "На данный момент ни одна команда не выбрала Ваш проект. Пожалуйста, проверьте эту информацию позже.",
    mentorProfileDontStart: "На текущий момент мероприятие еще не началось, просим Вас подождать его старта.",
    // Page certificate
    pageCertificate: "Проверка сертификата",
    // Leader Board
    boardData: "Данные по баллам обновляются в 12:00 по МСК",
    boardWithOutData: "К сожалению, на данный момент времени таблица лидеров скрыта до следующего этапа!",
    // Mentor case page
    mentorWithOutCase: "У Вас еще нет предложенных кейсов"


}

export default Strings