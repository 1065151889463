import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classes from "./AcordionBlock.module.css"
import TextField from '@mui/material/TextField';
import "./styleBlock.css"
import TableWithPart from './tableWithPart/TableWithPart';
import Button from '../buttons/button/Button';
import { useDeviceType } from 'components/hooks/useDeviceType';
import { NotificationManager } from 'react-notifications';
import Notifications from "components/UI/popupNotifications/Notifications";
import Strings from 'values/Strings';
import InputBlock from '../inputBlock/InputBlock';

export default function ControlledAccordions({ table, ...props }) {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const copy = (data) => {
        if (data) {
            navigator.clipboard.writeText(data)
            NotificationManager.success(Strings.notificationsCopyData, Strings.notificationsSuccessTitle, 2500);
        }
    }

    const device = useDeviceType()

    return (
        <div>
            {device === "phone" ?
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className={classes.style__acordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className={classes.style__summary}
                    >
                        <Typography sx={{ width: '50%', flexShrink: 0 }} className={classes.style__text__for__block}>
                            Команда: {props.teamName}
                        </Typography>
                        {/* <Typography sx={{ width: '50%', flexShrink: 0 }} className={classes.style__text__for__block}>
                            Номер стола: {props.tableNumber}
                        </Typography> */}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={{ width: '100%', flexShrink: 0 }} className={classes.style__text__for__block}>
                            Кейс: {props.caseName}
                        </Typography>
                        <div className={classes.style__second__block}>
                            <TableWithPart table={table} />
                        </div>
                        <div className={classes.row__style}>
                            <InputBlock placeholder={"Презентация команды"} onClick={() => copy(props.presentation == null ? "-" : props.presentation)} readOnly={true} onListStatus={false} value={props.presentation == null ? "-" : props.presentation}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            <InputBlock placeholder={"Ссылка на GitVerse"} onClick={() => copy(props.addational_material == null ? "-" : props.addational_material)} readOnly={true} onListStatus={false} value={props.addational_material == null ? "-" : props.addational_material}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        </div>
                    </AccordionDetails>
                </Accordion>
                :
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className={classes.style__acordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={classes.icon} />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className={classes.style__summary}
                    >
                        <Typography sx={{ width: '18%', flexShrink: 0 }} className={classes.style__text__for__block}>
                            Команда: {props.teamName}
                        </Typography>
                        {/* <Typography sx={{ width: '13%', flexShrink: 0 }} className={classes.style__text__for__block}>
                            Номер стола: {props.tableNumber}
                        </Typography> */}
                        <Typography sx={{ width: '63%', flexShrink: 0 }} className={classes.style__text__for__block}>
                            Кейс: {props.caseName}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.style__second__block}>
                            <TableWithPart table={table} />
                        </div>
                        <div className={classes.row__style}>
                            {/* <div style={{ display: "flex", gap: "1vw" }}> */}
                            <InputBlock placeholder={"Презентация команды"} onClick={() => copy(props.presentation == null ? "-" : props.presentation)} readOnly={true} onListStatus={false} value={props.presentation == null ? "-" : props.presentation}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            {/* </div> */}
                            <InputBlock placeholder={"Ссылка на GitVerse."} onClick={() => copy(props.addational_material == null ? "-" : props.addational_material)} readOnly={true} onListStatus={false} value={props.addational_material == null ? "-" : props.addational_material}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        </div>
                    </AccordionDetails>
                </Accordion>
            }
            <Notifications />
        </div>

    );
}