import FinalMark from "components/UI/grade/finalMark/FinalMark";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSidebarItem } from "redux/slice/sidebarItemSlice";
import Strings from "values/Strings";
function TeamGradeFinalMark() {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setSidebarItem(Strings.sidebarGrade))
    }, [])

    return (
        <div>
            <FinalMark />
        </div>
    );
}

export default TeamGradeFinalMark;