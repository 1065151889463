import classes from "./PackMarkButton.module.css"
import CircleButtonMark from "../CircleButtonMark"
import "@fontsource/roboto"
import { useState, useRef } from 'react'
import Colors from "values/Colors"
import Tooltip from "rc-tooltip"
import 'rc-tooltip/assets/bootstrap_white.css';
import { useDeviceType } from "components/hooks/useDeviceType"
import { useEffect } from "react"
import { useCallback } from "react"


const PackMarkButton = ({ children, setValue, name, buttonCount = 4, idButton = -1, setIsSaved, borderTop = true, flex = false, textWidth = true, ...props }) => {
    const ref = useRef()
    let colorList = [Colors.markRed, Colors.markRed, Colors.markOrange, Colors.markOrange, Colors.markGreen, Colors.markGreen]
    if (buttonCount === 2) {
        colorList = [Colors.markRed, Colors.markGreen]
    }
    else if (buttonCount === 3) {
        colorList = [Colors.markRed, Colors.markYellow, Colors.markGreen]
    }

    const changeColor = useCallback((idButton) => {
        for (let i = 0; i < ref.current.children.length; i++) {
            const item = ref.current.children[i]
            if (idButton === i) {
                item.style.backgroundColor = Colors.blue2
                setValue(old => ({ ...old, [name]: i }))
            } else {
                item.style.backgroundColor = colorList[i]
            }
        }
    }, [idButton])

    const device = useDeviceType()

    useEffect(() => {
        // if (idButton !== -1) {
        changeColor(idButton)
        // }
    }, [idButton])

    const buttonPress = (index) => {
        setIsSaved(false)
        changeColor(index)
    }


    return (
        (device === 'phone' ?
            <div className={classes.style__block} style={{ borderTop: (device === 'phone' && borderTop) ? "0.053vw rgb(235, 235, 235) solid" : "none" }}>
                <span className={classes.style__span} style={{ display: textWidth ? "block" : "none" }}>{props.text}</span>
                <div className={classes.block__with__mark} ref={ref}>
                    {colorList.map((color, index) => <CircleButtonMark key={index} text={index} color={color} onClick={() => buttonPress(index)} />)}
                </div>
            </div >
            :
            <div className={classes.style__block} style={{ borderTop: (device === 'phone' && borderTop) ? "0.053vw rgb(235, 235, 235) solid" : "none", flexDirection: flex ? "row" : "column" }}>
                <Tooltip placement="top" trigger={'hover'} overlay={<span style={{ fontSize: "0.7vw", fontFamily: "Roboto" }} className={classes.tooltip__style}>{props.hint}</span>}>
                    <span className={classes.style__span}>{props.text}</span>
                </Tooltip>
                <div className={classes.block__with__mark} ref={ref}>
                    {colorList.map((color, index) => <CircleButtonMark key={index} text={index} color={color} onClick={() => buttonPress(index)} />)}
                </div>
            </div >
        )
    )
}

export default PackMarkButton