import classes from "./TeamRoom.module.css"
import { useState, useRef, useEffect } from "react";
import "@fontsource/roboto";
import InputBlock from "components/UI/inputBlock/InputBlock"
import Button from "components/UI/buttons/button/Button";
import 'rc-tooltip/assets/bootstrap_white.css';
import QrGenerator from "components/UI/qrCode/qrGenerator/QrGenerator";
import { useDeviceType } from "components/hooks/useDeviceType";
import { Link, useNavigate } from 'react-router-dom';
import { useFetching } from 'components/hooks/useFetching';
import Strings from 'values/Strings';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import TeamRoomService from "services/TeamRoomService";
import Colors from "values/Colors";
import Loader from "../loader/Loader";
import { useDispatch, useSelector } from 'react-redux';
import { changeLoaderStatus } from "redux/slice/loaderSlice"
import { publickRoutNames } from "router";
import OAuthService from "services/OAuthService";
import { AiOutlineClose } from "react-icons/ai";
import DragAndDrop from "../dragAndDrop/DragAndDrop";
import DragAndDropPlaceholder from "../dragAndDrop/DragAndDropPlaceholder";
import { newName } from "redux/slice/titleSlice";
import TitleMain from "../titleMain/TitleMain";

const TeamRoom = ({ children, currentTask, choiceTask, task, mentor, listParticipants, teamRm, setTeamRm, taskList, fetchingTeamRm, loading, ...props }) => {

    const ref = useRef()
    const device = useDeviceType()
    const dispatch = useDispatch()
    const navigator = useNavigate()


    useEffect(() => {
        dispatch(newName(Strings.teamRoom))
    }, [])



    const notificationError = (error) => {
        if (error.length > 0) {
            NotificationManager.error(JSON.stringify(error), Strings.notificationsErrorTitle, 5000);
        }
    }


    const [fetchingLeaveCommand, loadingLeaveCommand, errorLeaveCommand] = useFetching(async (id) => {
        try {
            await TeamRoomService.getLeaveCommand(id)
            localStorage.removeItem("command")
            // dispatch(setCommand({}))
            navigator(`/${publickRoutNames.mainInfo}`)
            NotificationManager.success(Strings.notificationsLeaveCommand, Strings.notificationsSuccessTitle, 2500);
        } catch (error) {
            notificationError(error)
        } finally {
            // dispatch(changeLoaderStatus(false))
        }
    })

    // -------------------------------------DragAndDrop------------------------------------
    const [dragEndDropText, setDragEndDropText] = useState("")
    const dragZoneRef = useRef()

    function imagePreview(imageFile) {
        setTeamRm(old => ({ ...old, presentation: imageFile }))
        NotificationManager.success(Strings.notificationsAddFile, Strings.notificationsSuccessTitle, 2500);
    }

    function removeImage() {
        setTeamRm(old => ({ ...old, presentation: null }))
        NotificationManager.success(Strings.notificationsRemoveFile, Strings.notificationsSuccessTitle, 2500);
    }

    return (
        <div ref={ref} className={classes.main__block__style}>
            {device === "phone"
                ? <div className={classes.first__block__style}>
                    <InputBlock indexStatus={true} readOnly={true} placeholder={"Выбор кейса"} value={currentTask ? currentTask : ''} setValue={choiceTask} refMainContainer={ref}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                </div>
                : <div className={classes.first__block__style}>
                    <div style={{ display: "flex" }}>
                        <InputBlock placeholder={"Выбор кейса"} indexStatus={true} readOnly={true} value={currentTask ? currentTask : ''} setValue={choiceTask} refMainContainer={ref}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                    </div>
                </div>
            }
            {device === "phone"
                ? mentor?.length > 0 && <div className={classes.first__block__style} style={{ marginBottom: "5vw" }}>
                    <InputBlock indexStatus={true} readOnly={true} placeholder={"Ссылка для связи с ментором"} value={mentor ? mentor : ''} refMainContainer={ref}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                    <a style={{ outline: 'none', textDecoration: "none" }} href={`http://t.me/${mentor}`} target="_blank"><Button>Открыть</Button></a>
                </div>
                : mentor?.length > 0 && <div className={classes.first__block__style}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                        <InputBlock placeholder={"Ссылка для связи с ментором"} width={52.5} indexStatus={true} readOnly={true} value={mentor ? mentor : ''} refMainContainer={ref}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        <a style={{ outline: 'none', textDecoration: "none" }} href={`http://t.me/${mentor}`} target="_blank"><Button>Открыть</Button></a>
                    </div>
                </div>
            }

            {device === "phone"
                ? <div className={classes.git__user__style__more}>
                    <InputBlock value={teamRm?.table_number === '0' ? Strings.teamRoomDesk0 : teamRm?.table_number} readOnly={true} placeholder={"Номер стола команды"} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                </div>
                :
                <div className={classes.git__user__style}>
                    <InputBlock placeholder={"Номер стола команды"} value={teamRm?.table_number === '0' ? Strings.teamRoomDesk0 : teamRm?.table_number} readOnly={true} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                </div>
            }
            <div className={classes.present__and__content__style}>
                <TitleMain text={"Результаты работы команды"} jsc="center" />
                {device === "phone"
                    ? teamRm?.presentation
                        ? <div className={classes.down__block__style} onClick={removeImage}>
                            <div className={classes.drop__down__style}>
                                <span>Удалить презентацию</span>
                                <AiOutlineClose color="rgb(210, 12, 12)" />
                            </div>
                        </div>
                        : <DragAndDrop justifyContent="left" imagePreview={imagePreview} borderStyle={'none'} dragZoneRef={dragZoneRef}
                            setDragZoneText={setDragEndDropText} textDragZone="Добавить презентацию">
                            <DragAndDropPlaceholder height={'10vw'} imagePreview={imagePreview} dragZoneRef={dragZoneRef}>{dragEndDropText}</DragAndDropPlaceholder>
                        </DragAndDrop>
                    :
                    <div className={classes.git__user__style}>
                        {teamRm?.presentation
                            ? <div className={classes.presentation__wrapper}>
                                <div className={classes.presentation__container}>
                                    <span>Презентация загружена</span> <AiOutlineClose onClick={removeImage} color="rgb(210, 12, 12)" />
                                </div>
                            </div>
                            : <DragAndDrop justifyContent="left" imagePreview={imagePreview} borderStyle={'none'} dragZoneRef={dragZoneRef}
                                setDragZoneText={setDragEndDropText} textDragZone="Вставить презентацию">
                                <DragAndDropPlaceholder height={'15vw'} width={'60vw'} right={'0vw'} imagePreview={imagePreview} dragZoneRef={dragZoneRef}>{dragEndDropText}</DragAndDropPlaceholder>
                            </DragAndDrop>
                        }
                    </div>
                }

                {device === "phone"
                    ? <div className={classes.git__user__style}>
                        <InputBlock setValue={char => setTeamRm(old => ({ ...old, additional_material: char }))} value={teamRm?.additional_material ? teamRm?.additional_material : ''} placeholder={"Ссылка на GitVerse"} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                    </div>

                    :
                    <div className={classes.git__user__style}>
                        <InputBlock placeholder={"Ссылка на GitVerse"} setValue={char => setTeamRm(old => ({ ...old, additional_material: char }))}
                            value={teamRm?.additional_material ? teamRm?.additional_material : ''} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>

                    </div>
                }
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button onClick={() => fetchingTeamRm(teamRm)}>Сохранить</Button>
                </div>
            </div>


            <div className={classes.second__block__style}>
                <div className={classes.list__participants__style}>
                    <TitleMain text={"Список участников"} jsc="center" />
                    {/* тут будет список участников */}
                    <div className={classes.list__style}>
                        {listParticipants.map((item, i) => <span key={i}>{item}</span>)}
                    </div>

                </div>
                <div className={classes.description__block__style}>
                    <TitleMain text={"События по времени"} jsc="center" />
                    <div className={classes.description__for__team}>
                        <div className={classes.for__padding__style}>
                            <span className={classes.description__span__style} style={{ whiteSpace: 'pre-wrap' }}>
                                {teamRm?.time_managment}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.qr__style}>
                <TitleMain text={"QR-код для участников команды"} jsc="center" />
                <QrGenerator url={teamRm?.command_link} />
            </div>
            <div className={classes.exit__button__style}>
                <Button onClick={() => fetchingLeaveCommand(OAuthService.decryptedSync('command').command)} color={Colors.red}>Покинуть</Button>
            </div>
        </div >
    )
}

export default TeamRoom